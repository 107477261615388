import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5e5a4327"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  class: "pl"
};
const _hoisted_3 = {
  class: "warp"
};
const _hoisted_4 = {
  class: "box"
};
const _hoisted_5 = {
  class: "box-left"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "user"
};
const _hoisted_8 = {
  class: "box-right"
};
const _hoisted_9 = {
  class: "describe"
};
const _hoisted_10 = ["innerHTML"];
const _hoisted_11 = {
  class: "createTime"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
    title: "资料详情",
    "left-text": "返回",
    "left-arrow": "",
    onClickLeft: $options.onClickLeft
  }, null, 8, ["onClickLeft"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
    src: $data.baseimg + $data.info.avatar
  }, null, 8, _hoisted_6), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", null, _toDisplayString($data.info.createBy), 1)])]), _createElementVNode("div", _hoisted_8, [$data.info.like ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    onClick: _cache[0] || (_cache[0] = (...args) => $options.like && $options.like(...args)),
    src: "https://meco-1259435766.cos.ap-shanghai.myqcloud.com/tc/home/tester/experience/dz2.png"
  })) : (_openBlock(), _createElementBlock("img", {
    key: 1,
    onClick: _cache[1] || (_cache[1] = (...args) => $options.like && $options.like(...args)),
    src: "https://meco-1259435766.cos.ap-shanghai.myqcloud.com/tc/home/tester/experience/dz1.png"
  })), _createElementVNode("div", null, _toDisplayString($data.info.upvote), 1)])]), _createElementVNode("div", _hoisted_9, _toDisplayString($data.info.title), 1), _createElementVNode("div", {
    class: "fwb",
    innerHTML: $data.info.articleContent
  }, null, 8, _hoisted_10), _createElementVNode("div", _hoisted_11, _toDisplayString($data.info.createTime), 1)])])])], 64);
}