import { dataControllerdetail, upvote } from "../../api/application/index";
export default {
  data() {
    return {
      info: {},
      query: {},
      activeindex: 0,
      html: '<h1>标题</h1><p>正文</p><p><img src=\"/prod-api/profile/upload/2023/05/31/18d1e4b6-7499-494a-8b1d-dac94c7dde9b.jpg\"></p>',
      // baseimg:'https://www.allslug.com/prod-api'
      baseimg: this.$store.state.imgurl
    };
  },
  created() {
    let query = this.$route.query;
    console.log(query);
    this.query = query;
    this.getdetail();
  },
  methods: {
    getdetail() {
      dataControllerdetail({
        id: this.query.id,
        userId: localStorage.getItem('userId')
      }).then(res => {
        console.log(res);
        this.info = res.data;
      });
    },
    like() {
      console.log(this.info.id);
      let id = this.info.id;
      upvote({
        articleId: id,
        //文章id
        userId: localStorage.getItem('userId')
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.getdetail();
        }
      });
    },
    onClickLeft() {
      this.$router.back(-1);
    }
  }
};